/**
 * Scroll to top
 * @type {HTMLElement}
 */
let scrollToTopBtn = document.getElementById("scrollToTopBtn")
let rootElement = document.documentElement
let TOGGLE_RATIO = 0.5

function handleScroll() {
    // do something on scroll
    let scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
    if ((rootElement.scrollTop / scrollTotal) > TOGGLE_RATIO) {
        //show button
        scrollToTopBtn.classList.add("showBtn")
    } else {
        //hide button
        scrollToTopBtn.classList.remove("showBtn")
    }
}

function scrollToTop() {
    //scroll to top logic
    rootElement.scrollTo({
        top: 0,
        behavior: "smooth"
    })
}
scrollToTopBtn.addEventListener("click", scrollToTop)
document.addEventListener("scroll", handleScroll)