/**
 * Header
 */
function headerFixedTop()
{
    let header  = document.getElementById("header");
    let content = document.getElementById("content");

    let headerOffsetHeight = header.offsetHeight;

    if ( header.classList.contains("fixed-top") ) {
        content.style.marginTop = headerOffsetHeight + "px";
    }
}

document.addEventListener("DOMContentLoaded", headerFixedTop);