/**
 * GLightbox
 * @link https://github.com/biati-digital/glightbox
 */
import GLightbox from 'glightbox';

const lightbox = GLightbox({
    selector: ".lightbox",
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
});