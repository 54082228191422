/**
 * JavaScript Cookie
 * @link https://github.com/js-cookie/js-cookie
 */
// import Cookies from 'js-cookie'

/**
 * Bootstrap
 * @link https://getbootstrap.com/
 */
import { Collapse, Dropdown, Tooltip, Modal, ScrollSpy } from 'bootstrap';

let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
});

/**
 * Theme Functions (My)
 */
import './functions';

/**
 * webpack CSS export
 */
import '../scss/index.scss';